@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

.main {
  width: 90%;
  padding: 0 10px;
  margin: 0px auto;
  margin-top: 4rem;
  border: 1px solid transparent;
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}
