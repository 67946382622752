@import "../../_globalColor";

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > :last-child {
  flex: 0.4;
}

.contact-div-main > :first-child {
  flex: 0.6;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail-link {
  font-size: 0.875rem;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;

  > i {
    margin-right: 0.75rem;
  }
}

.contact-detail-link:hover {
  color: $skillsColor;
}

.contact-subtitle {
  text-transform: uppercase;
}

.contact-text-div {
  margin-top: 0.25rem;
}

.contact-margin-top {
  margin-top: 3rem !important;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}

.contact-social-media {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    margin-bottom: 1.25rem;
  }

  .contact-image-div {
    display: none;
  }

  .contact-text-div {
    text-align: center;
  }

  .contact-div-main > :first-child {
    flex: 1;
  }

  .contact-social-media {
    text-align: center;
  }
}
